import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, UNSAFE_DataRouterStateContext } from 'react-router-dom';
import './Styles/ChapterPages.css';
import config from "../config";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const Bookpages = () => {
  const { book_id } = useParams();
  const history = useNavigate();
  const [chapterTitle, setChapterTitle] = useState('');
  const [bookTitle, setBookTitle] = useState('');
  const [pages, setPages] = useState([]);
  const [resources, setResources] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    fetch(`${config.apiBaseUrl}/fullmarks-user/home/fetchbookpages.php?book_id=${book_id}`)
      .then(response => response.json())
      .then(data => {
        if (data && data.length > 0) {
         
            setChapterTitle(data[0].chapter_name);
            setBookTitle(data[0].book_name);
            setPages(data); 
          
        }
      })
      .catch(error => console.error('Error fetching chapter pages:', error));
  }, [book_id]);


  
  
  

  useEffect(() => {
    // Fetching resources for the chapter
    fetch(`${config.apiBaseUrl}/fullmarks-user/home/fetchResourcesforBook.php?book_id=${book_id}`)
      .then(response => response.json())
      .then(data => setResources(data))
      .catch(error => console.error('Error fetching resources:', error));
  }, [book_id]);

  const handleNextPage = () => {
    if (currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextChapter = () => {
    const nextChapterId = parseInt(book_id) + 1;
    history(`/chapterpages/${nextChapterId}`);
  };

  const handlePrevChapter = () => {
    const prevChapterId = parseInt(book_id) - 1;
    if (prevChapterId > 0) {
      history(`/chapterpages/${prevChapterId}`);
    }
  };

  const handleFullView = () => {
    const fullViewUrl = `${config.apiBaseUrl}/admin/fullmarks-server/uploads/book_pages/${pages[currentPage].image_path}`;
    window.open(fullViewUrl, '_blank');
  };

  const handleShareChapter = () => {
    const shareUrl = window.location.href;
    navigator.clipboard.writeText(shareUrl).then(() => {
      alert('URL copied to clipboard!');
    }).catch((err) => {
      console.error('Failed to copy the URL: ', err);
    });
  };

  const renderResources = (type) => {
    const filteredResources = resources.filter(
      (resource) => resource.resource_type === type
    );

    if (filteredResources.length === 0) {
      return (
        <div className="no-resources">
          No resources available for this type.
        </div>
      );
    }

    return (
      <div className="resource-table-container">
        <table className="resource-table">
          <thead>
            <tr>
              <th>Files</th>
            </tr>
          </thead>
          <tbody>
            {filteredResources.map((resource) => (
              <tr key={resource.resource_id}>
                <td>
                  {/* Files Column */}
                  {type === "videos" && resource.video_file && (
                    <div>
                      <video className="resource-video" controls>
                        <source
                          src={`${config.apiBaseUrl}/admin/fullmarks-server/uploads/videos/${resource.video_file}`}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  )}
                  {type === "documents" && resource.document_file && (
                    <div>
                      <a
                        href={`${config.apiBaseUrl}/admin/fullmarks-server/uploads/documents/${resource.document_file}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="resource-link"
                      >
                        View Document
                      </a>
                    </div>
                  )}
                  {type === "audio" && resource.audio_file && (
                    <div>
                      <audio className="resource-audio" controls>
                        <source
                          src={`${config.apiBaseUrl}/admin/fullmarks-server/uploads/audios/${resource.audio_file}`}
                          type="audio/mpeg"
                        />
                      </audio>
                    </div>
                  )}
                  {type === "interactivities" &&
                    resource.interactivities_file && (
                      <div>
                        <a
                          href={`${config.apiBaseUrl}/admin/fullmarks-server/uploads/interactivities/${resource.interactivities_file}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="resource-link"
                        >
                          Interactive Activity
                        </a>
                      </div>
                    )}
                  {type === "addnactivities" &&
                    resource.addnactivities_file && (
                      <div>
                        <a
                          href={`${config.apiBaseUrl}/admin/fullmarks-server/uploads/addnactivities/${resource.addnactivities_file}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="resource-link"
                        >
                          Additional Activity
                        </a>
                      </div>
                    )}
                  {type === "answerkeys" && resource.answerkeys_file && (
                    <div>
                      <a
                        href={`${config.apiBaseUrl}/admin/fullmarks-server/uploads/answerkeys/${resource.answerkeys_file}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="resource-link"
                      >
                        Answer Key
                      </a>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <table className="resource-table">
          <thead>
            <tr>
              <th>Links</th>
            </tr>
          </thead>
          <tbody>
            {filteredResources.map((resource) => (
              <tr key={resource.resource_id}>
                <td>
                  {/* Links Column */}
                  {type === "videos" && resource.video_link && (
                    <iframe
                      src={resource.video_link}
                      border="0"
                      rel="noopener noreferrer"
                      className="resource-link"
                    >
                    </iframe>
                  )}
                  {type === "documents" && resource.document_link && (
                    <a
                      href={resource.document_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="resource-link"
                    >
                      External Document Link
                    </a>
                  )}
                  {type === "audio" && resource.audio_link && (
                    <a
                      href={resource.audio_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="resource-link"
                    >
                      Listen to Audio
                    </a>
                  )}
                  {type === "interactivities" &&
                    resource.interactivities_link && (
                      <a
                        href={resource.interactivities_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="resource-link"
                      >
                        External Interactivity
                      </a>
                    )}
                  {type === "addnactivities" &&
                    resource.addnactivities_link && (
                      <a
                        href={resource.addnactivities_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="resource-link"
                      >
                        External Additional Activity
                      </a>
                    )}
                  {type === "answerkeys" && resource.answerkeys_link && (
                    <a
                      href={resource.answerkeys_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="resource-link"
                    >
                      External Answer Key
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  


  return ( 
    <div className="mt-5">
      <div className='border diskcha'>
        <p className='text-center h2 mt-4 text-uppercase fw-bold text-white'>
          {bookTitle}
        </p>
      </div>
      <div>
       
      <div className='d-flex justify-content-end'>
        <button className='mx-3 btn btn-sm btn-warning'>
          <i className="bi bi-chevron-double-left"></i>
        </button>
        <button className='mx-3 btn btn-sm btn-warning'>
          <i className="bi bi-chevron-double-right"></i>
        </button>
        <button className='mx-3 btn btn-sm btn-info' onClick={handleFullView}>
          <i className="mx-2 bi bi-fullscreen"></i>
        </button>
        <button className='mx-3 btn btn-info' onClick={handleShareChapter}>
          <i className="bi bi-share-fill mx-2"></i>
        </button>
      </div>
      <hr />
       

        <div className="page-navigation-container p-3">
          <div className="page-content">
            {pages.length > 0 ? (
              <div className="border">
                <img
                  src={`${config.apiBaseUrl}/admin/fullmarks-server/uploads/book_pages/${pages[currentPage].image_path}`}
                  alt={`Page ${currentPage + 1}`}
                  className="page-image p-3"
                />
              </div>
            ) : (
              <div className="no-pages-found">No pages found</div>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-center">
          {currentPage > 0 && (
            <span className="nav-arrow left-arrow" onClick={handlePrevPage}>
              <i className="bi bi-arrow-left-square-fill"></i>
            </span>
          )}
          {currentPage < pages.length - 1 && (
            <span className="nav-arrow right-arrow" onClick={handleNextPage}>
              <i className="bi bi-arrow-right-square-fill"></i>
            </span>
          )}
        </div>

        <div className="px-5 mt-5 resources-container">
        <h3>Resources for this Book</h3>
        {resources.length > 0 ? (
          <>
            {renderResources('videos', 'Videos')}
            {renderResources('audio', 'Audio')}
            {renderResources('documents', 'Documents')}
            {renderResources('interactivities', 'Interactivities')}
            {renderResources('addnworksheets', 'Worksheets')}
            {renderResources('addnactivities', 'Additional Activities')}
          </>
        ) : (
          <div>No resources available for this chapter.</div>
        )}
      </div>

       
      </div>
    </div>
  );
};

export default Bookpages;
